var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"记录收付款"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"iotplt-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"客户名称"}},[_c('span',[_vm._v(_vm._s(_vm.agent.name))])]),_c('a-form-item',{attrs:{"label":"收付款类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['transfer_type',{
            initialValue: 'offline_deposit',
            rules: [
              { required: true, message: '请选择收付款类型' } ]
          }]),expression:"['transfer_type',{\n            initialValue: 'offline_deposit',\n            rules: [\n              { required: true, message: '请选择收付款类型' },\n            ]\n          }]"}],on:{"change":_vm.handleTransferTypeChange}},[_c('a-radio',{attrs:{"value":"offline_deposit"}},[_vm._v(" 收款 ")]),_c('a-radio',{attrs:{"value":"withdrawal"}},[_vm._v(" 付款 ")])],1)],1),_c('a-form-item',{attrs:{"label":_vm.payWayTitle}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pay_way', {
            rules: [{ required: true, message: '请选择方式' }]
          }]),expression:"['pay_way', {\n            rules: [{ required: true, message: '请选择方式' }]\n          }]"}],attrs:{"loading":_vm.loadingPayWayOptions},on:{"focus":_vm.fetchPayWayOptions}},_vm._l((_vm.payWayOptions),function(option){return _c('a-select-option',{key:option.slug,attrs:{"value":option.slug}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":_vm.amountTitle}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['amount', {
            rules: [
              { required: true, message: '请输入金额' },
              { validator: _vm.checkDistributorPrice }
            ]
          }]),expression:"['amount', {\n            rules: [\n              { required: true, message: '请输入金额' },\n              { validator: checkDistributorPrice }\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"min":0.01,"max":10000000,"step":0.01,"precision":2}}),_c('span',[_vm._v("*可以输入小数点后两位")])],1),_c('a-form-item',{attrs:{"label":_vm.paidAtTitle}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['paid_at', {
            initialValue: this.$moment(),
            rules: [
              { required: true, message: '请选择日期' } ]
          }]),expression:"['paid_at', {\n            initialValue: this.$moment(),\n            rules: [\n              { required: true, message: '请选择日期' },\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"disabled-date":_vm.disabledPaidAt}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            rules: [
              { max: 200, message: '最多200个字符' },
              { required: true, message: '请输入备注' }
            ]
          }]),expression:"['remark', {\n            rules: [\n              { max: 200, message: '最多200个字符' },\n              { required: true, message: '请输入备注' }\n            ]\n          }]"}],attrs:{"auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }