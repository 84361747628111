<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="记录收付款"
      @ok="handleSubmit"
    >
      <a-form
        class="iotplt-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="客户名称">
          <span>{{ agent.name }}</span>
        </a-form-item>

        <a-form-item label="收付款类型">
          <a-radio-group
            @change="handleTransferTypeChange"
            v-decorator="['transfer_type',{
              initialValue: 'offline_deposit',
              rules: [
                { required: true, message: '请选择收付款类型' },
              ]
            }]"
          >
            <a-radio value="offline_deposit">
              收款
            </a-radio>
            <a-radio value="withdrawal">
              付款
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item :label="payWayTitle">
          <a-select
            @focus="fetchPayWayOptions"
            :loading="loadingPayWayOptions"
            v-decorator="['pay_way', {
              rules: [{ required: true, message: '请选择方式' }]
            }]"
          >
            <a-select-option
              v-for="option in payWayOptions"
              :key="option.slug"
              :value="option.slug"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label="amountTitle">
          <a-input-number
            style="width: 100%"
            :min="0.01"
            :max="10000000"
            :step="0.01"
            :precision="2"
            v-decorator="['amount', {
              rules: [
                { required: true, message: '请输入金额' },
                { validator: checkDistributorPrice }
              ]
            }]"
          />
          <span>*可以输入小数点后两位</span>
        </a-form-item>

        <a-form-item :label="paidAtTitle">
          <a-date-picker
            style="width: 100%;"
            :disabled-date="disabledPaidAt"
            v-decorator="['paid_at', {
              initialValue: this.$moment(),
              rules: [
                { required: true, message: '请选择日期' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item :label="`备注`">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { max: 200, message: '最多200个字符' },
                { required: true, message: '请输入备注' }
              ]
            }]"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createAgencyTransfer, findTransferPayWayOptions } from '@/api/transfer'
import { findAgentName, findAgentFinanceOverview } from '@/api/agent'
import { formatDate } from '@/utils/time'

export default {
  name: 'NewAgencyTransfer',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      payWayTitle: '收款方式',
      amountTitle: '收款金额(元)',
      paidAtTitle: '收款日期',
      payWayOptions: [],
      loadingPayWayOptions: false,
      agent: {},
      submitting: false,
      form: this.$form.createForm(this, { name: 'agency_stransfer' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    checkDistributorPrice(rule, value, callback) {
      if (this.form.getFieldValue('transfer_type') === 'offline_deposit') {
        callback()
        return
      }
      findAgentFinanceOverview(this.agentId).then((res) => {
        if (res.code === 0) {
          if (this.form.getFieldValue(`${rule.field.split('.')[0]}`) > res.data.balance) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('付款后余额小于0，无法进行付款!')
            return
          }
          callback()
        }
      })
    },

    disabledPaidAt(current) {
      return current && current > this.$moment().endOf('day')
    },

    fetchFormData() {
      findAgentName(this.agentId).then((res) => {
        this.agent = res.data
      })
    },

    // 处理 收付款类型 变更 字段名称进行改变
    handleTransferTypeChange(e) {
      if (e.target.value === 'offline_deposit') {
        this.payWayTitle = '收款方式'
        this.amountTitle = '收款金额(元)'
        this.paidAtTitle = '收款日期'
      } else {
        this.payWayTitle = '付款方式'
        this.amountTitle = '付款金额(元)'
        this.paidAtTitle = '付款日期'
      }
      this.form.setFieldsValue({ transfer_type: e.target.value })
      if (this.form.getFieldValue('amount') === undefined) {
        return
      }
      this.form.validateFields(['amount'], { force: true })
    },

    fetchPayWayOptions() {
      if (this.payWayOptions.length === 0) {
        this.loadingPayWayOptions = true
        findTransferPayWayOptions().then(res => {
          if (res.code === 0) {
            this.payWayOptions = res.data
          }
          this.loadingPayWayOptions = false
        })
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            agent_id: this.agentId,
            paid_at: formatDate(values.paid_at)
          }
          createAgencyTransfer(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
